import React from "react"; // , { useEffect, useState }
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Button from "../../form-components/Button";
import { all_routes } from "../../../Router/all_routes";

const PrintOrderConfirmation = ({ cart, grandTotal: totalAmount, hide }) => {
  console.log(cart, totalAmount, hide);
  const navigate = useNavigate();

  const orderDetails = {
    transactionId: "NA",
    date: Date.now(),
    name: "NA",
    totalAmount, // Assuming totalAmount is already calculated
    orderItems: cart.map((item) => ({
      name: item.name,
      unitPrice: item.unitPrice,
      quantitySold: item.quantity,
      lineTotalAmount: item.unitPrice * item.quantity,
    })),
  };

  const handlePrint = () => {
    navigate(all_routes.salesvoucher, {
      state: {
        transactionInfo: orderDetails,
        documentType: "order-confirmation",
      },
    });
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <button type="button" className="close p-0" onClick={hide}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="icon-head text-center">
          <Link to="#">
            <ImageWithBasePath
              src="assets/img/logo.png"
              width={100}
              height={30}
              alt="Invoice Logo"
            />
          </Link>
        </div>
        <div className="text-center info">
          <h6>Diamond Hotel Kipe</h6>
          <p className="mb-0">Phone Number: +224 629696944 / 620354668</p>
          <p className="mb-0">
            Email:
            <Link to="mailto:example@gmail.com">info@diamondhotelkipe.com</Link>
          </p>
        </div>
        <div className="tax-invoice">
          <h6 className="text-center">Restaurant Order Confirmation</h6>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="invoice-user-name">
                <span>Name: </span>
                <span>{name || "N/A"}</span>
              </div>
              <div className="invoice-user-name">
                <span>Confirmation No: </span>
                <span>{orderDetails.transactionId || "N/A"}</span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="invoice-user-name">
                <span>Date:{orderDetails.date} </span>
                {/* <span>{date || "N/A"}</span> */}
              </div>
            </div>
          </div>
        </div>
        <table className="table-borderless w-100 table-fit">
          <thead>
            <tr>
              <th># Item</th>
              <th>Price (GNF)</th>
              <th>Qty</th>
              <th className="text-end">Total</th>
            </tr>
          </thead>
          <tbody>
            {orderDetails.orderItems && orderDetails.orderItems.length > 0 ? (
              orderDetails.orderItems.map((item, key) => (
                <tr key={key}>
                  <td>{item.name}</td>
                  <td>{item.unitPrice}</td>
                  <td>{item.quantitySold}</td>
                  <td className="text-end">
                    {item.unitPrice * item.unitPrice}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No items found
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={4}>
                <table className="table-borderless w-100 table-fit">
                  <tbody>
                    <tr>
                      <td>Sub Total :</td>
                      <td className="text-end">{`GNF ${totalAmount}`}</td>
                    </tr>
                    <tr>
                      <td>Total Payable :</td>
                      <td className="text-end">{`GNF ${totalAmount}`}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="text-center invoice-bar">
          <p>Thank You For Dinning With Us. Please Come Again</p>
          <Button
            color="info"
            label="Print Order Confirmation"
            onClick={handlePrint}
          />
        </div>
      </div>
    </>
  );
};

PrintOrderConfirmation.propTypes = {
  cart: PropTypes.array,
  hide: PropTypes.any,
  grandTotal: PropTypes.number,
};

export default PrintOrderConfirmation;

import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import useGetMealOrdersStatuses from "../../core/hooks/useGetMealOrdersStatuses";

const MealOrderDisplay = () => {
  const { data: pendingMeals, isSuccess } = useGetMealOrdersStatuses();

  const getColorClass = (status) => {
    switch (status) {
      case "PENDING":
        return "bg-outline-danger";
      case "PREPARING":
        return "bg-outline-warning";
      case "READY":
        return "bg-outline-success";
      default:
        return "bg-outline-secondary";
    }
  };

  const getButtonLabel = (status) => {
    switch (status) {
      case "PENDING":
        return "Pending";
      case "PREPARING":
        return "Preparing";
      case "READY":
        return "Ready";
      default:
        return "Unknown";
    }
  };

  return (
    <div className="page-wrapper" style={{ margin: 0 }}>
      <div className="content">
        <div className="row">
          {isSuccess &&
            pendingMeals?.data?.orders?.map((item, key) => (
              <div className="col-sm-4 col-md-4 col-xl-3 d-flex" key={key}>
                <div className="card flex-fill default-cover w-100 mb-4">
                  <div
                    className={`card-header d-flex justify-content-between bg-${getColorClass(
                      item.status
                    )} align-items-center`}
                  >
                    <h4 className="card-title mb-0">Order #{item?.orderId}</h4>
                    <div className="dropdown">
                      <span className="ps-100 d-flex align-items-center">
                        <FontAwesomeIcon
                          icon={faBullhorn}
                          data-bs-toggle="tooltip"
                          title="fa fa-shopping-basket"
                          color="white"
                          size="400"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-borderless best-seller">
                        <tbody>
                          {item.meals.map((meal, key) => (
                            <tr key={key}>
                              <td>{`x${meal?.quantity}`}</td>
                              <td>{meal?.name}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan="2">
                              <div
                                className="d-grid mb-4"
                                style={{ width: "100%;" }}
                              >
                                <span
                                  className={`badge badge-lg  ${getColorClass(
                                    item?.status
                                  )}`}
                                >
                                  {getButtonLabel(item?.status)}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MealOrderDisplay;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { base_path } from "./environment.jsx";
import "../src/style/css/feather.css";
import "../src/style/css/line-awesome.min.css";
import "../src/style/scss/main.scss";
import "../src/style/icons/fontawesome/css/fontawesome.min.css";
import "../src/style/icons/fontawesome/css/all.min.css";
import { createRoot } from "react-dom/client";
import AllRoutes from "./Router/router.jsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./core/context/AuthProvider.jsx";
import ErrorBoundary from "./feature-module/pages/errorpages/ErrorBoundary.jsx";
import * as bootstrap from "bootstrap";
import { ModalProvider } from "./core/context/ModalContext.js";
import store from "./core/redux/store.jsx";
import { Provider } from "react-redux";
window.bootstrap = bootstrap;
const rootElement = document.getElementById("root");

if (rootElement) {
  const queryClient = new QueryClient();
  //   {
  //   defaultOptions: {
  //     queries: {
  //       staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes
  //       cacheTime: 30 * 60 * 1000, // Cache persists for 30 minutes
  //       refetchOnWindowFocus: false, // Disable auto-refetch on window focus
  //       retry: 1, // Retry failed queries once
  //     },
  //   },
  // }
  const root = createRoot(rootElement);
  root.render(
    <ErrorBoundary>
      <React.StrictMode>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <ModalProvider>
                <BrowserRouter basename={base_path}>
                  <AllRoutes />
                </BrowserRouter>
              </ModalProvider>
            </Provider>
          </QueryClientProvider>
        </AuthProvider>
      </React.StrictMode>
    </ErrorBoundary>
  );
} else {
  console.error("Element with id 'root' not found.");
}

import React from "react";
import PropTypes from "prop-types";

const SelectInput = ({
  label,
  register,
  options,
  errors,
  onChange,
  idSelected,
}) => {
  return (
    <>
      <label>{label}</label>
      <select
        className="form-select"
        {...register}
        onChange={onChange || (() => {})}
      >
        {options.map((option, key) => (
          <option
            key={key}
            value={option.value}
            {...(idSelected && idSelected === key ? "selected" : "")}
          >
            {option.label}
          </option>
        ))}
      </select>
      {errors && <p className="text-danger">{errors.message}</p>}
    </>
  );
};

SelectInput.propTypes = {
  register: PropTypes.any.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  errors: PropTypes.any,
  onChange: PropTypes.func,
  idSelected: PropTypes.number,
};

export default SelectInput;

import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import {
  kitchenDisplayRoutes,
  mealOrderDisplayRoutes,
  pagesRoute,
  posRoutes,
  protectedRoutes,
} from "./router.link";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
import PersistLogin from "./PersistLogin";
import Error404 from "../feature-module/pages/errorpages/error404";

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      {/* <Loader /> */}
      <Header />
      <Sidebar />
      <Outlet />
    </div>
  );

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      {/* <Loader /> */}
    </div>
  );

  const Pospages = () => (
    <div>
      <Header />
      <Outlet />
      {/* <Loader /> */}
    </div>
  );

  const Kitchendisplaypages = () => (
    <div>
      <Header />
      <Outlet />
      {/* <Loader /> */}
    </div>
  );
  const MealOrderdisplaypages = () => (
    <div>
      <Header />
      <Outlet />
      {/* <Loader /> */}
    </div>
  );

  return (
    <div>
      <Routes>
        <Route element={<PersistLogin />}>
          <Route element={<Kitchendisplaypages />}>
            {kitchenDisplayRoutes.map((route, id) => (
              <Route
                element={
                  <ProtectedRoute
                    allowedPermission={route.allowedPermissions}
                  />
                }
                key={id}
              >
                <Route path={route.path} element={route.element} key={id} />
              </Route>
            ))}
          </Route>
          <Route element={<Pospages />}>
            {posRoutes.map((route, id) => (
              <Route
                element={
                  <ProtectedRoute
                    allowedPermission={route.allowedPermissions}
                  />
                }
                key={id}
              >
                <Route path={route.path} element={route.element} key={id} />
              </Route>
            ))}
          </Route>
          <Route element={<MealOrderdisplaypages />}>
            {mealOrderDisplayRoutes.map((route, id) => (
              <Route
                element={
                  <ProtectedRoute
                    allowedPermission={route.allowedPermissions}
                  />
                }
                key={id}
              >
                <Route path={route.path} element={route.element} key={id} />
              </Route>
            ))}
          </Route>
          <Route element={<HeaderLayout />}>
            {protectedRoutes.map((route, id) => (
              <Route
                element={
                  <ProtectedRoute
                    allowedPermission={route.allowedPermissions}
                  />
                }
                key={id}
              >
                <Route path={route.path} element={route.element} key={id} />
              </Route>
            ))}
          </Route>
          <Route path={"/"} element={<Authpages />}>
            {pagesRoute.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
          {/* Error page route */}
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AllRoutes;

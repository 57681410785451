import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";
import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetPendingMealOrders = () => {
  const { getAll } = usePrivateAPIClient(baseUrl, "/api/v1/kitchen", true);

  return useQuery({
    queryKey: ["pending-meals"],
    queryFn: () => getAll(),
    refetchInterval: 5000, // Fetches data every 5 seconds
    refetchOnWindowFocus: true, // Refetches when the window is refocused
    staleTime: 0, // Ensures data is never considered stale
    cacheTime: 0, // Prevents caching, forcing a reload on every query
  });
};

export default useGetPendingMealOrders;

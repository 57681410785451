import React from "react";
import withReactContent from "sweetalert2-react-content";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import TextInput from "../../form-components/TextInput";
import useAddExpenseCategory from "../../hooks/useAddExpenseCategory";
import Button from "../../form-components/Button";
import useAuth from "../../hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { EXPENSE_CATEGORIES_QUERY_KEY } from "../../queries/queryKeys";

const schema = z.object({
  name: z.string().min(1, { message: "Name cannot be empty" }),
  description: z.string().min(1, { message: "Description cannot be empty" }),
});

const AddExpenseCategory = () => {
  const addExpenseCategory = useAddExpenseCategory();
  const MySwal = withReactContent(Swal);
  const { auth } = useAuth();
  const queryClient = useQueryClient();
  const showCreateConfirmation = (handleCreateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to create this product category?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, create it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCreateConfirmation();
        queryClient.invalidateQueries([EXPENSE_CATEGORIES_QUERY_KEY]);
      } else {
        MySwal.close();
      }
    });
  };

  const handleCreateConfirm = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "images") {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });
    formData.append("userId", auth.userId);
    addExpenseCategory.mutate(formData, {
      onSuccess: () => {
        MySwal.fire({
          title: "Created!",
          text: "The expense category has been created.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          queryClient.invalidateQueries([EXPENSE_CATEGORIES_QUERY_KEY]);
        });
      },
      onError: () => {
        MySwal.fire({
          title: "Not Created!",
          text:
            addExpenseCategory?.error?.response?.data?.error?.message ||
            "Failed to create expense category.",
          icon: "error",
          confirmButtonText: "OK",
        });
      },
    });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    try {
      showCreateConfirmation(() => {
        handleCreateConfirm(data);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <TextInput
                        label="Name"
                        register={register("name")}
                        errors={errors.name}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Description"
                        register={register("description")}
                        errors={errors.description}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <Button
                        color="cancel"
                        label=" Cancel"
                        margin="me-2"
                        onClick={() => reset()}
                      />
                      <Button color="info" label="Create Category" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default AddExpenseCategory;

import { useMutation } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useUpdateMeal = () => {
  const { update } = usePrivateAPIClient(baseUrl, "/api/v1/meals", true);
  return useMutation({
    mutationFn: ({ id, formData: data }) =>
      update(id, data, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
  });
};

export default useUpdateMeal;

import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { all_routes } from "../../../Router/all_routes";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useAuth from "../../../core/hooks/useAuth";
import useUserLogin from "./../../../core/hooks/useUserLogin";
import TextInput from "../../../core/form-components/TextInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { jwtDecode } from "jwt-decode";
import CheckboxInput from "../../../core/form-components/CheckboxInput";
import Button from "../../../core/form-components/Button";
import PageLoader from "./../../../core/loader/pageloader";
import Alert from "../../../core/alert/Alert";
// Define the schema using zod
const schema = z.object({
  username: z.string().nonempty("Username is required"),
  password: z.string().nonempty("Password is required"),
  rememberMe: z.boolean(),
});

const Signin = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      rememberMe: localStorage.getItem("DHK_RETAIL_REMEMBER_ME") === "true",
    },
  });

  const userLogin = useUserLogin();
  const location = useLocation();
  const fromChangeReset = location.state?.fromChangeReset || false;

  const onSubmit = (data) => {
    const login = {
      username: data.username,
      password: data.password,
    };

    userLogin.mutate(login, {
      onSuccess: (responseData) => {
        const decodedJwt = jwtDecode(responseData.accessToken);
        const { username, permissions, role, userId } = decodedJwt.userInfo;
        setAuth({
          username,
          permissions,
          role,
          accessToken: responseData.accessToken,
          userId,
        });
        localStorage.setItem("DHK_RETAIL_REMEMBER_ME", data.rememberMe);
        navigate(route.dashboard, { replace: true });
      },
    });
  };

  const route = all_routes;
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <>
      {userLogin.isPending && <PageLoader />}
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper login-new">
            <div className="container">
              <div className="login-content user-login">
                <div className="login-logo">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                </div>
                <form method="post" onSubmit={handleSubmit(onSubmit)}>
                  <div className="login-userset">
                    <div className="login-userheading">
                      <h3>Sign In</h3>
                      <h4>
                        Access the Dreamspos panel using your email and
                        passcode.
                      </h4>
                      {userLogin.error && (
                        <Alert
                          label="Incorrect Username or Password. Please try again"
                          theme="danger"
                        />
                      )}
                      {fromChangeReset && (
                        <Alert
                          label="Password change successful. Please log in with your new password"
                          theme="danger"
                        />
                      )}
                    </div>
                    <div className="form-login">
                      <div className="form-addons">
                        <TextInput
                          label="Username"
                          register={register("username")}
                          errors={errors.username}
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/mail.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="form-login">
                      <div className="pass-group">
                        <TextInput
                          label="Password"
                          register={register("password")}
                          errors={errors.password}
                          type="password"
                        />
                        <span
                          className={`fas toggle-password ${
                            isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                          }`}
                          onClick={togglePasswordVisibility}
                        ></span>
                      </div>
                    </div>
                    <div className="form-login authentication-check">
                      <div className="row">
                        <div className="col-6">
                          <div className="custom-control custom-checkbox">
                            <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                              <CheckboxInput
                                register={register("rememberMe")}
                                id="rememberMe"
                                label="Remember me"
                                icon={<span className="checkmarks" />}
                                errors={errors.rememberMe}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <Link
                            className="forgot-link"
                            to={route.forgotPassword}
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="form-login">
                      <div className="d-grid gap-2 mb-4">
                        <Button color="secondary" label="Sign In" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>Copyright © 2024 Diamond Hotel Kipe. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;

import React, { useEffect } from "react";
import withReactContent from "sweetalert2-react-content";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import TextInput from "../../form-components/TextInput";
import SelectInput from "../../form-components/SelectInput";
import TextAreaInput from "./../../form-components/TextAreaInput";
import useUpdateExpense from "../../hooks/useUpdateExpense";
import PropTypes from "prop-types";
import Button from "../../form-components/Button";
import useAuth from "../../hooks/useAuth";
import useGetExpenseStatuses from "../../hooks/useGetExpenseStatuses";
import useGetExpenseCategories from "../../hooks/useGetExpenseCategories";

const schema = z.object({
  categoryId: z.number().min(1, { message: "Category cannot be empty" }),
  amount: z.number().min(1, { message: "Amount cannot be empty" }),
  expenseFor: z.string().min(1, { message: "Expense for cannot be empty" }),
  description: z
    .string()
    .min(1, { message: "Description for cannot be empty" }),
  statusId: z.number().min(1, { message: "Status cannot be empty" }),
});

const EditExpense = ({ currentRecord: expense }) => {
  const { data: statusData = [] } = useGetExpenseStatuses([]);

  const statusOptions =
    statusData.data?.expenseCategoryStatuses?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  const { data: expenseCategoryData } = useGetExpenseCategories();

  const categoryLookup =
    expenseCategoryData?.data?.expenseCategories?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  console.log("Status Data:", statusData);
  console.log("Category Data:", expenseCategoryData);

  const updateExpense = useUpdateExpense();

  const MySwal = withReactContent(Swal);

  const { auth } = useAuth();

  const showCreateConfirmation = (handleCreateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to update this expense?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, order it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCreateConfirmation();
      } else {
        MySwal.close();
      }
    });
  };

  const handleCreateConfirm = (data) => {
    console.log(data);
    data.userId = auth.userId;
    updateExpense.mutate(
      { id: expense.id, data },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Updated!",
            text: "The expense has been updated.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        onError: () => {
          MySwal.fire({
            title: "Not Updated!",
            text:
              updateExpense?.error?.response?.data?.error?.message ||
              "Failed to update expense",
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (expense) {
      reset({
        categoryId: expense.categoryId,
        amount: expense.amount,
        expenseFor: expense.expenseFor,
        description: expense.description,
        statusId: expense.statusId,
      });
    }
  }, [expense, reset]);

  const onSubmit = (data) => {
    try {
      showCreateConfirmation(() => handleCreateConfirm(data));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Expense</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <TextInput
                        label="Expense For"
                        register={register("expenseFor")}
                        errors={errors.expenseFor}
                      />
                    </div>
                    <div className="mb-3">
                      <TextAreaInput
                        label="Description"
                        register={register("description")}
                        errors={errors.description}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Amount"
                        register={register("amount", {
                          setValueAs: (v) => parseInt(v, 10),
                        })}
                        errors={errors.amount}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectInput
                        label="Category"
                        options={categoryLookup}
                        register={register("categoryId", {
                          setValueAs: (v) => parseInt(v, 10),
                        })}
                        errors={errors.categoryId}
                      />
                    </div>
                    <div className="mb-0">
                      <SelectInput
                        label="Status"
                        options={statusOptions}
                        register={register("statusId", {
                          setValueAs: (v) => parseInt(v, 10),
                        })}
                        errors={errors.statusId}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <Button
                        color="cancel"
                        label=" Cancel"
                        margin="me-2"
                        onClick={() => reset()}
                      />
                      <Button color="info" label="Update Category" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};
EditExpense.propTypes = {
  currentRecord: PropTypes.any.isRequired,
};

export default EditExpense;

import React from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { useLocation } from "react-router-dom";

const MealDetail = () => {
  const location = useLocation();
  const { meal } = location.state || {};

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Meal Details</h4>
              <h6>Full details of a meal</h6>
            </div>
          </div>
          {/* /add */}
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="bar-code-view">
                    <ImageWithBasePath
                      src="assets/img/barcode/barcode1.png"
                      alt="barcode"
                    />
                    <a className="printimg">
                      <ImageWithBasePath
                        src="assets/img/icons/printer.svg"
                        alt="print"
                      />
                    </a>
                  </div>
                  <div className="productdetails">
                    <ul className="product-bar">
                      <li>
                        <h4>Meal</h4>
                        <h6>{meal.name}</h6>
                      </li>
                      <li>
                        <h4>Description</h4>
                        <h6>{meal.description}</h6>
                      </li>
                      <li>
                        <h4>Category</h4>
                        <h6>{meal.category}</h6>
                      </li>
                      <li>
                        <h4>Price</h4>
                        <h6>{meal.unitPrice}</h6>
                      </li>
                      <li>
                        <h4>Date</h4>
                        <h6>{meal.date}</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="slider-product-details">
                    <div className="owl-carousel owl-theme product-slide">
                      <div className="slider-product">
                        <ImageWithBasePath
                          alt=""
                          src={meal.imageUrl}
                          type="R"
                        />
                        <h4>{meal.name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default MealDetail;

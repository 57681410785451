import {
  ChevronUp,
  Edit,
  Eye,
  PlusCircle,
  RotateCcw,
  Trash2,
} from "feather-icons-react/build/IconComponents";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { all_routes } from "../../Router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import { setToogleHeader } from "../../core/redux/action";
import useGetProducts from "../../core/hooks/useGetProducts";
import useDeleteProduct from "../../core/hooks/useDeleteProduct";
import useAuth from "../../core/hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { PRODUCTS_QUERY_KEY } from "../../core/queries/queryKeys";
import useGetProductCategories from "../../core/hooks/useGetProductCategories";
import useGetSuppliers from "../../core/hooks/useGetSuppliers";

const ProductList = () => {
  const { auth } = useAuth();
  const queryClient = useQueryClient();
  const { data: productData } = useGetProducts();
  const deleteProduct = useDeleteProduct();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const MySwal = withReactContent(Swal);

  const { data: productCategories } = useGetProductCategories();
  const { data: suppliers } = useGetSuppliers();
  const modifiedCategories =
    productData?.data?.products?.map((product) => {
      const category = productCategories?.data?.productCategories?.find(
        (entry) => entry.id === product.categoryId
      );
      const supplier = suppliers?.data?.suppliers?.find(
        (entry) => entry.id == product.supplierId
      );
      return {
        ...product,
        category: category ? category?.name : "Unknown",
        supplier: supplier?.name,
      };
    }) || [];

  const showDeleteConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeleteConfirm = (id) => {
    deleteProduct.mutate(
      { id, userId: auth.userId },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Deleted!",
            text: "The product has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([PRODUCTS_QUERY_KEY]);
          });
        },
        onError: () => {
          MySwal.fire({
            title: "Not Deleted!",
            text:
              deleteProduct?.error?.response?.data?.error?.message ||
              "Failed to delete product.",
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };
  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      render: (text, record) => {
        return (
          <span className="productimgname">
            <Link to="#" className="product-img stock-img">
              <ImageWithBasePath alt="" src={record.imageUrl} type="R" />
            </Link>
            <Link to="/profile">{text}</Link>
          </span>
        );
      },
      sorter: (a, b) => a.product.length - b.product.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.sku.length - b.sku.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: (a, b) => a.category.length - b.category.length,
    },

    {
      title: "Price",
      dataIndex: "unitPrice",
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: "Quantity On Hand",
      dataIndex: "quantityOnHand",
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: "Reorder Level",
      dataIndex: "reorderLevel",
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="action-table-data">
            <div className="edit-delete-action">
              <div className="input-block add-lists"></div>
              <Link
                className="me-2 p-2"
                to={all_routes.productdetails}
                state={{ product: record }}
              >
                <Eye className="feather-view" />
              </Link>
              <Link
                className="me-2 p-2"
                to={all_routes.editproduct}
                state={{ product: record }}
              >
                <Edit className="feather-edit" />
              </Link>
              <Link
                className="confirm-text p-2"
                to="#"
                onClick={() =>
                  showDeleteConfirmationAlert(() =>
                    handleDeleteConfirm(record.id)
                  )
                }
              >
                <Trash2 className="feather-trash-2" />
              </Link>
            </div>
          </div>
        );
      },
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Product List</h4>
              <h6>Manage your products</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
          <div className="page-btn">
            <Link to={all_routes.addproduct} className="btn btn-added">
              <PlusCircle className="me-2 iconsize" />
              Add New Product
            </Link>
          </div>
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <Table columns={columns} dataSource={modifiedCategories} />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default ProductList;

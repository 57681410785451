import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CheckCircle } from "react-feather";
import PropTypes from "prop-types";
import Button from "../../form-components/Button";
import PrintInvoice from "./printinvoice";
import SelectPaymentMethod from "./selectpaymentmethod";
import AddedToInvoice from "./addedtoinvoice";
import Modal from "../Modal";
const OrderCompleted = ({ bookingIdentifier, invoiceId, hide }) => {
  const isGuest = bookingIdentifier > 0;

  const [isPrintInvoiceModelOpen, setIsPrintInvoiceModelOpen] = useState(false);
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] =
    useState(false);
  const [isAddedToInvoiceModalOpen, setIsAddedToInvoiceModalOpen] =
    useState(false);
  const handleAddPayment = async () => {
    setIsPaymentMethodModalOpen(true);
  };
  const handleClick = () => {
    setIsPrintInvoiceModelOpen(true);
    // hide();
  };
  return (
    <>
      <div className="d-flex justify-content-end">
        <button type="button" className="close p-0" onClick={hide}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body text-center">
        <div className="icon-head">
          <Link to="#">
            <CheckCircle className="feather-40" />
          </Link>
        </div>
        <h4>Order Completed</h4>
        <p className="mb-0">
          {isGuest
            ? "The order has been added to customers bill"
            : "Do you want to Print Invoice for the Order or Pay Directly"}
        </p>
        {!isGuest && (
          <div className="modal-footer d-sm-flex justify-content-between">
            <Button color="info" label="Print Invoice" onClick={handleClick} />
            <Button
              color="success"
              label={"Pay Now"}
              onClick={handleAddPayment}
            />
          </div>
        )}
      </div>
      <Modal modalId="print-invoice" show={isPrintInvoiceModelOpen}>
        <PrintInvoice
          invoiceId={invoiceId}
          hide={() => setIsPrintInvoiceModelOpen(false)}
        />
      </Modal>
      <Modal modalId="payment-method" show={isPaymentMethodModalOpen}>
        <SelectPaymentMethod
          invoiceId={invoiceId}
          hide={() => setIsPaymentMethodModalOpen(false)}
        />
      </Modal>
      <Modal modalId="added-to-invoice" show={isAddedToInvoiceModalOpen}>
        <AddedToInvoice hide={() => setIsAddedToInvoiceModalOpen(false)} />
      </Modal>
    </>
  );
};

OrderCompleted.propTypes = {
  bookingIdentifier: PropTypes.number,
  invoiceId: PropTypes.number,
  hide: PropTypes.any,
};

export default OrderCompleted;

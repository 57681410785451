import React from "react";
import { Route } from "react-router-dom";
import ProductList from "../feature-module/inventory/productlist";
import Dashboard from "../feature-module/dashboard/Dashboard";
import AddProduct from "../feature-module/inventory/addproduct";
import SalesDashbaord from "../feature-module/dashboard/salesdashbaord";
import BrandList from "../feature-module/inventory/brandlist";
import ExpensesList from "../feature-module/FinanceAccounts/expenseslist";
import ExpenseCategory from "../feature-module/FinanceAccounts/expensecategory";
import CategoryList from "../feature-module/inventory/categorylist";
import PurchasesList from "../feature-module/purchases/purchaseslist";
import Suppliers from "../feature-module/people/suppliers";
import Pos from "../feature-module/sales/pos";
import Signin from "../feature-module/pages/login/signin";
import SigninTwo from "../feature-module/pages/login/signinTwo";
import SigninThree from "../feature-module/pages/login/signinFour";
import RegisterTwo from "../feature-module/pages/register/registerTwo";
import Register from "../feature-module/pages/register/register";
import RegisterThree from "../feature-module/pages/register/registerThree";
import Forgotpassword from "../feature-module/pages/forgotpassword/forgotpassword";
import EmailVerification from "../feature-module/pages/emailverification/emailverification";
import EmailverificationTwo from "../feature-module/pages/emailverification/emailverificationTwo";
import EmailverificationThree from "../feature-module/pages/emailverification/emailverificationThree";
import Twostepverification from "../feature-module/pages/twostepverification/twostepverification";
import TwostepverificationTwo from "../feature-module/pages/twostepverification/twostepverificationTwo";
import TwostepverificationThree from "../feature-module/pages/twostepverification/twostepverificationThree";
import Lockscreen from "../feature-module/pages/lockscreen";
import Error404 from "../feature-module/pages/errorpages/error404";
import Error500 from "../feature-module/pages/errorpages/ErrorBoundary";
import Comingsoon from "../feature-module/pages/comingsoon";
import Undermaintainence from "../feature-module/pages/undermaintainence";
import { all_routes } from "./all_routes";
import KitchenDisplay from "../feature-module/sales/kitchendisplay";
import MealList from "../feature-module/meals/meallist";
import MealCategoryList from "../feature-module/meals/mealcategorylist";
import AddMeal from "../feature-module/meals/addmeal";
import Resetpassword from "../feature-module/pages/resetpassword/resetpassword";
import Logout from "../feature-module/pages/logout/logout";
import ProductDetail from "./../feature-module/inventory/productdetail";
import EditProduct from "./../feature-module/inventory/editproduct";
import MealDetail from "./../feature-module/meals/mealdetail";
import EditMeal from "./../feature-module/meals/editmeal";
import ChangePassword from "../feature-module/pages/changepassword/changepassword";
import SalesVoucher from "../feature-module/sales/sales-voucher";
import MealOrderDisplay from "../feature-module/sales/mealorderdisplay";
const routes = all_routes;

export const protectedRoutes = [
  {
    id: 1,
    path: routes.dashboard,
    name: "home",
    element: <Dashboard />,
    allowedPermissions: { HOME: ["READ"] },
  },
  {
    id: 2,
    path: routes.productlist,
    name: "products",
    element: <ProductList />,
    allowedPermissions: { PRODUCT: ["READ"] },
  },
  {
    id: 3,
    path: routes.addproduct,
    name: "products",
    element: <AddProduct />,
    allowedPermissions: { PRODUCT: ["CREATE"] },
    route: Route,
  },
  {
    id: 4,
    path: routes.salesdashboard,
    name: "salesdashboard",
    element: <SalesDashbaord />,
    allowedPermissions: { HOME: ["READ"] },
    route: Route,
  },
  {
    id: 5,
    path: routes.brandlist,
    name: "brant",
    element: <BrandList />,
    allowedPermissions: { HOME: ["READ"] },
    route: Route,
  },
  {
    id: 6,
    path: routes.categorylist,
    name: "categorylist",
    element: <CategoryList />,
    allowedPermissions: { PRODUCT_CATEGORY: ["READ"] },
    route: Route,
  },
  {
    id: 7,
    path: routes.expenselist,
    name: "expenselist",
    element: <ExpensesList />,
    allowedPermissions: { EXPENSE: ["READ"] },
    route: Route,
  },
  {
    id: 8,
    path: routes.expensecategory,
    name: "expensecategory",
    element: <ExpenseCategory />,
    allowedPermissions: { EXPENSE_CATEGORY: ["READ"] },
    route: Route,
  },
  {
    id: 9,
    path: routes.purchaselist,
    name: "purchaselist",
    element: <PurchasesList />,
    allowedPermissions: { PURCHASE: ["READ"] },
    route: Route,
  },
  {
    id: 10,
    path: routes.suppliers,
    name: "suppliers",
    element: <Suppliers />,
    allowedPermissions: { SUPPLIER: ["READ"] },
    route: Route,
  },
  {
    id: 11,
    path: routes.meallist,
    name: "meals",
    element: <MealList />,
    allowedPermissions: { MEAL: ["READ", "CREATE", "UPDATE", "DELETE"] },
    route: Route,
  },
  {
    id: 12,
    path: routes.mealcategorylist,
    name: "meals",
    element: <MealCategoryList />,
    allowedPermissions: {
      MEAL_CATEGORY: ["READ", "CREATE", "UPDATE", "DELETE"],
    },
    route: Route,
  },
  {
    id: 13,
    path: routes.addmeal,
    name: "meals",
    element: <AddMeal />,
    allowedPermissions: { MEAL: ["CREATE"] },
    route: Route,
  },
  {
    id: 14,
    path: routes.logout,
    name: "logout",
    element: <Logout />,
    allowedPermissions: { HOME: ["READ"] },
  },
  {
    id: 15,
    path: routes.productdetails,
    name: "productdetails",
    element: <ProductDetail />,
    route: Route,
    allowedPermissions: { PRODUCT: ["READ"] },
  },
  {
    id: 16,
    path: routes.editproduct,
    name: "editproduct",
    element: <EditProduct />,
    route: Route,
    allowedPermissions: { PRODUCT: ["UPDATE"] },
  },
  {
    id: 17,
    path: routes.mealdetails,
    name: "mealdetails",
    element: <MealDetail />,
    route: Route,
    allowedPermissions: { MEAL: ["READ"] },
  },
  {
    id: 18,
    path: routes.editmeal,
    name: "editmeal",
    element: <EditMeal />,
    route: Route,
    allowedPermissions: { MEAL: ["UPDATE"] },
  },
  {
    id: 18,
    path: routes.changepassword,
    name: "changepassword",
    element: <ChangePassword />,
    route: Route,
    allowedPermissions: { HOME: ["READ"] },
  },
  {
    id: 19,
    path: routes.salesvoucher,
    name: "salesvoucher",
    element: <SalesVoucher />,
    route: Route,
    allowedPermissions: {
      INVOICE: ["READ"],
      RECEIPT: ["READ"],
      PURCHASE: ["READ"],
    },
  },
];

export const posRoutes = [
  {
    id: 1,
    path: routes.pos,
    name: "pos",
    element: <Pos />,
    allowedPermissions: { POS: ["CREATE", "READ", "UPDATE", "DELETE"] },
    route: Route,
  },
];

export const kitchenDisplayRoutes = [
  {
    id: 1,
    path: routes.kitchendisplay,
    name: "kitchen-display",
    element: <KitchenDisplay />,
    allowedPermissions: { KDS: ["CREATE", "READ", "UPDATE", "DELETE"] },
    route: Route,
  },
];

export const mealOrderDisplayRoutes = [
  {
    id: 1,
    path: routes.mealordersdisplay,
    name: "meal-order-display",
    element: <MealOrderDisplay />,
    allowedPermissions: { MOD: ["CREATE", "READ", "UPDATE", "DELETE"] },
    route: Route,
  },
];

export const pagesRoute = [
  {
    id: 1,
    path: routes.signin,
    name: "signin",
    element: <Signin />,
    route: Route,
  },
  {
    id: 2,
    path: routes.signintwo,
    name: "signintwo",
    element: <SigninTwo />,
    route: Route,
  },
  {
    id: 3,
    path: routes.signinthree,
    name: "signinthree",
    element: <SigninThree />,
    route: Route,
  },
  {
    id: 4,
    path: routes.register,
    name: "register",
    element: <Register />,
    route: Route,
  },
  {
    id: 5,
    path: routes.registerTwo,
    name: "registerTwo",
    element: <RegisterTwo />,
    route: Route,
  },
  {
    id: 6,
    path: routes.registerThree,
    name: "registerThree",
    element: <RegisterThree />,
    route: Route,
  },

  {
    id: 8,
    path: routes.forgotPassword,
    name: "forgotPassword",
    element: <Forgotpassword />,
    route: Route,
  },
  {
    id: 9,
    path: routes.resetpassword,
    name: "resetpassword",
    element: <Resetpassword />,
    route: Route,
  },

  {
    id: 12,
    path: routes.emailverification,
    name: "emailverification",
    element: <EmailVerification />,
    route: Route,
  },
  {
    id: 12,
    path: routes.emailverificationTwo,
    name: "emailverificationTwo",
    element: <EmailverificationTwo />,
    route: Route,
  },
  {
    id: 13,
    path: routes.emailverificationThree,
    name: "emailverificationThree",
    element: <EmailverificationThree />,
    route: Route,
  },
  {
    id: 14,
    path: routes.twostepverification,
    name: "twostepverification",
    element: <Twostepverification />,
    route: Route,
  },
  {
    id: 15,
    path: routes.twostepverificationTwo,
    name: "twostepverificationTwo",
    element: <TwostepverificationTwo />,
    route: Route,
  },
  {
    id: 16,
    path: routes.twostepverificationThree,
    name: "twostepverificationThree",
    element: <TwostepverificationThree />,
    route: Route,
  },
  {
    id: 17,
    path: routes.lockscreen,
    name: "lockscreen",
    element: <Lockscreen />,
    route: Route,
  },
  {
    id: 18,
    path: routes.error404,
    name: "error404",
    element: <Error404 />,
    route: Route,
  },
  {
    id: 19,
    path: routes.error500,
    name: "error500",
    element: <Error500 />,
    route: Route,
  },
  {
    id: 20,
    path: routes.comingsoon,
    name: "comingsoon",
    element: <Comingsoon />,
    route: Route,
  },
  {
    id: 21,
    path: routes.undermaintenance,
    name: "undermaintenance",
    element: <Undermaintainence />,
    route: Route,
  },
];

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CheckCircle } from "react-feather";
import PropTypes from "prop-types";
import Button from "../../form-components/Button";
import PrintReceipt from "./printreceipt";
import Modal from "../Modal";
const PaymentCompleted = ({ receiptId }) => {
  console.log(receiptId);
  const [isPrintReceiptOpen, setIsPrintReceiptOpen] = useState(false);
  return (
    <>
      <div className="modal-body text-center">
        <div className="icon-head">
          <Link to="#">
            <CheckCircle className="feather-40" />
          </Link>
        </div>
        <h4>Payment Completed</h4>
        <p className="mb-0">Do you want to Print Receipt for the Order</p>
        <div className="d-grid gap-2 mb-4">
          <Button
            color="success"
            label="Print Receipt"
            onClick={() => {
              setIsPrintReceiptOpen(true);
            }}
          />
        </div>
      </div>
      <Modal modalId="print-receipt" show={isPrintReceiptOpen}>
        <PrintReceipt receiptId={receiptId} />
      </Modal>
    </>
  );
};
PaymentCompleted.propTypes = {
  receiptId: PropTypes.number,
  hide: PropTypes.func,
};

export default PaymentCompleted;

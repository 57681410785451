import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetPosOrderNo = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/pos/order-number",
    true
  );
  return useQuery({
    queryKey: ["pos-order-number"],
    queryFn: () => getAll(),
  });
};

export default useGetPosOrderNo;

import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetDashboardMetrics = () => {
  const apiClient = usePrivateAPIClient(
    baseUrl,
    "/api/v1/dashboard-metrics",
    true
  );
  return useQuery({
    queryKey: ["dashboard-metrics"],
    queryFn: () => apiClient.getAll(),
    staleTime: 0, // Data becomes stale immediately for real-time updates
    refetchInterval: 5000, // Refetch data every 5 seconds
  });
};
export default useGetDashboardMetrics;
